.saved-jobs-container {
    padding: 20px;
}

h1 {
    text-align: center;
    margin-bottom: 30px;
}

.job-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.job-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s;
}

.job-card:hover {
    transform: translateY(-5px);
}

.job-card-header {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.job-card-header h2 {
    font-size: 1.5em;
    margin: 0;
}

.job-card-header p {
    font-size: 0.9em;
    color: #555;
}

.job-card-body p {
    margin: 5px 0;
}

.job-card-footer {
    margin-top: 20px;
}

.cv-link {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
}

.cv-link:hover {
    text-decoration: underline;
}
