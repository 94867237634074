/* Ajustements pour assurer la cohérence entre les écrans */
.navbar-container {
    position: relative;
    background-color: white;
}

.profile-menu {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 9999;
    width: 200px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal {
    position: fixed;
    inset: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Flexbox pour uniformité */
.navbar-container ul {
    display: flex;
    align-items: center;
}

.navbar-container ul li {
    margin: 0 10px;
}