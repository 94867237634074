@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

/* Global Styles */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

/* Filter Header */
.filter-header {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.companies-listings-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.search-container {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.search-input {
  padding: 10px;
  width: 75%;
  max-width: auto;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  justify-content: center;
}

/* Companies Section */
.companies-section {
  padding: 30px;
  /* background-color: #f9f9f9; */
  /* Light gray background */
}

/* Clear Filters Button */
.clear-filters {
  padding: 10px 15px;
  background-color: #c7d2fe;
  border: none;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1;
  /* S'assure que le bouton prend de l'espace */
}

.clear-filters:hover {
  background-color: #94a8f6;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #333;
  /* Dark text for contrast */
}

/* Responsive grid */
.container {
  display: flex;
  flex-direction: column;
}

.companies-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Three columns */
  gap: 20px;
}

@media (max-width: 900px) {
  .companies-grid {
    grid-template-columns: repeat(2, 1fr);
    /* Two columns on smaller screens */
  }
}

@media (max-width: 600px) {
  .filter-header {
    flex-direction: column;
    /* Changer en colonne sur mobile */
    gap: 15px;
  }

  .companies-grid {
    grid-template-columns: 1fr;
    /* One column on very small screens */
  }

  .search-input,
  .clear-filters {
    width: 100%;
    /* Prendre toute la largeur */
    max-width: none;
    /* Désactiver max-width pour mobile */
  }
}

/* Company Card Styles */
.entreprise-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: white;
  /* White background for cards */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
}

.entreprise-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.entreprise-banner {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.entreprise-header {
  display: flex;
  align-items: center;
  padding: 10px;
}

.entreprise-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.entreprise-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  /* Blue for company name */
}

.company-info-container {
  padding: 10px;
}

.company-info {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  color: #555;
}

.icon {
  margin-right: 8px;
  color: #555;
}

/* Expanded Details */
.expanded-details {
  padding: 10px;
  background: #f1f1f1;
  /* Light gray for expanded details */
  border-top: 1px solid #ddd;
}

/* Button Styles */
.follow-button {
  margin-top: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  /* Blue background */
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.follow-button:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
}