/* Container */
.company-details-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Header */
.company-header {
    text-align: center;
    margin-bottom: 20px;
}

.company-banner {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
}

.company-banner img {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
}

.company-logo {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 100px;
    height: auto;
    border-radius: 8px;
    border: 2px solid #ddd;
    background-color: #fff;
    padding: 5px;
    text-align: center;
}

.company-logo img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    margin-bottom: 5px;
}

.company-name {
    font-weight: bold;
    font-size: 1.1rem;
    color: #333;
}

.company-badges {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
    flex-wrap: wrap;
}

.badge {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #e9ebef;
    padding: 8px 12px;
    border-radius: 12px;
    font-size: 0.9rem;
    color: #333;
}

.follow-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    font-size: 1rem;
    margin-top: 15px;
}

.follow-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

/* Job Section */
.company-jobs {
    margin-top: 20px;
}

.job-filters {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.job-filters input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.job-list {
    list-style: none;
    padding: 0;
}

.job-item {
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: box-shadow 0.3s;
}

.job-item:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .company-logo {
        width: 80px;
    }

    .company-badges {
        gap: 5px;
        font-size: 0.85rem;
    }

    .follow-button {
        padding: 8px 15px;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .company-details-container {
        padding: 10px;
    }

    .company-logo {
        width: 60px;
    }

    .company-banner img {
        max-height: 200px;
    }

    .badge {
        padding: 6px 8px;
        font-size: 0.8rem;
    }

    .follow-button {
        padding: 6px 12px;
        font-size: 0.85rem;
    }
}