/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;


/* :root {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  position: relative;
  list-style: none;
  cursor: pointer;
}

li>a:hover::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 4px;
  background: #3B82F6;
}

.valuecard {
  background-image: url(./assets/images/ValueBG.jpg);
  background-size: 200px;
} */