/* Global */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
}

/* Job Listings Container */
.job-listings-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

/* Filter Header */
.filter-header {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  max-width: 250px;
  /* Conserve max-width pour les grands écrans */
  transition: border-color 0.3s;
}

.search-bar:focus {
  border-color: #007bff;
}

/* Dropdown */
.filter-dropdown {
  position: relative;
  min-width: 140px;
  flex: 1;
  /* S'assure que le dropdown prend de l'espace */
}

.dropdown-header {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  background: #fff;
}

.caret {
  margin-left: auto;
  transition: transform 0.3s;
}

.caret.open {
  transform: rotate(180deg);
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-option {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

/* Clear Filters Button */
.clear-filters {
  padding: 8px 15px;
  background-color: #c7d2fe;
  border: none;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1;
  /* S'assure que le bouton prend de l'espace */
}

.clear-filters:hover {
  background-color: #94a8f6;
}

/* Job Cards */
.jobs-section {
  margin-top: 20px;
}

.jobs-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.job-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  transition: box-shadow 0.3s;
  position: relative;
}

.job-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.job-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job-title {
  font-size: 1.25rem;
}

.job-company {
  font-size: 0.9rem;
}

.job-details {
  font-size: 0.9rem;
  color: #666;
  margin: 10px 0;
}

.job-details p {
  display: inline;
  /* S'assurer que les éléments sont affichés sur la même ligne */
  margin-right: 10px;
  /* Ajoute de l'espace entre les éléments */
}

/* Load More Button */
.load-more-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.load-more {
  padding: 10px 20px;
  background-color: #4f46e5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.load-more:hover {
  background-color: #4338ca;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .filter-header {
    flex-direction: column;
    /* Changer en colonne sur mobile */
    gap: 15px;
  }

  .search-bar,
  .filter-dropdown,
  .clear-filters {
    width: 100%;
    /* Prendre toute la largeur */
    max-width: none;
    /* Désactiver max-width pour mobile */
  }
}