.blog-posts-area {
    padding: 3rem 1rem;
}

.title-section {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    color: #222;
    margin-bottom: 2rem;
}

.content-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between;
}

.main-content {
    flex: 1;
    max-width: 800px;
}

.sidebar {
    flex-basis: 300px;
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.news-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
}

.single-post {
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.single-post:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

img.img-fluid {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.title {
    font-size: 1.2rem;
    color: #333;
    font-weight: 600;
    margin-bottom: 0.5rem;
    transition: color 0.2s;
}

.title:hover {
    color: #007bff;
}

.content {
    color: #666;
    line-height: 1.6;
}

.collapsed {
    max-height: 120px;
    overflow: hidden;
}

.expanded {
    max-height: none;
}

.toggle-text {
    cursor: pointer;
    color: #007bff;
    font-weight: bold;
    display: inline-block;
    margin-top: 0.5rem;
}

.toggle-text:hover {
    text-decoration: underline;
}

.bottom-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    color: #999;
}

.social-wrap ul {
    display: flex;
    gap: 10px;
}

.social-wrap ul li a {
    color: #555;
    transition: color 0.2s;
}

.social-wrap ul li a:hover {
    color: #007bff;
}

.comment-wrap {
    margin-left: auto;
}

.comment-wrap a {
    display: flex;
    align-items: center;
    color: #999;
    font-size: 0.9rem;
}

.comment-wrap a:hover {
    color: #007bff;
}

@media (max-width: 768px) {
    .content-wrapper {
        flex-direction: column;
    }

    .main-content,
    .sidebar {
        max-width: 100%;
    }

    .news-grid {
        grid-template-columns: 1fr;
    }

    .title-section {
        font-size: 1.5rem;
    }

    .single-post {
        padding: 1rem;
    }

    .title {
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .blog-posts-area {
        padding: 2rem 0.5rem;
    }

    .title-section {
        font-size: 1.2rem;
    }

    .content-wrapper {
        gap: 1rem;
    }

    .single-post {
        padding: 0.8rem;
    }

    .bottom-meta {
        flex-direction: column;
        align-items: flex-start;
    }

    .comment-wrap a {
        font-size: 0.8rem;
    }
}