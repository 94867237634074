.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    /* Assure que le spinner soit centré verticalement */
}

.spinner-icon {
    font-size: 3rem;
    color: #007bff;
    /* Couleur bleue */
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}