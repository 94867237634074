.bg-blue-500 {
    background-color: #3B82F6;
    background-image: -webkit-linear-gradient(0deg, #82a5dd 0%, #3B82F6 100%) !important;
    /* Couleur bleu */
}

.text-white {
    color: white;
    /* Couleur du texte blanc */
}

.submit-left .submit-right {
    background-image: -webkit-linear-gradient(0deg, #82a5dd 0%, #3B82F6 100%) !important;
}